    
<!-- DON'T REMOVE pFocusTrap to avoid auto focus -->
<h2 pFocusTrap  >
    <img src="assets/img/logoOn.png" alt="On Logo">     
     
</h2> 

      
 <!-- MENU  -->
 <p-panelMenu [model]="menuitems" [multiple]="false" />    

 <!-- COMBO LANGUAGES  -->
 <p-dropdown id="lang"  
    styleClass="dropdownLang"
    [options]="comboLanguages()"   
    [(ngModel)]="selectedLang"
    placeholder = "Select Language...."
    optionLabel="label" > 
        <ng-template let-selectedOption   pTemplate="selectedItem"   > 
            <div class="comboItem">
                <img   [src]="selectedOption?.ico" />
                <div>{{ selectedOption?.label }}</div>
            </div> 
        </ng-template> 
        <ng-template let-language pTemplate="item" >
            <div class="comboItem"
                (click)="selectLang(language)" >
                <img [src]="language.ico"   />
                <div>{{ language.label }}</div>
            </div>
        </ng-template>
</p-dropdown> 


     
<app-app-version style="position: fixed; bottom: 10px;" />  

  

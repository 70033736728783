import { Component, CreateEffectOptions, EventEmitter, Input, Output, computed, effect, inject, signal  } from '@angular/core'; 
import { MatButtonModule } from '@angular/material/button'; 
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips'; 
import { AvatarModule } from 'primeng/avatar';
import { DatePipe, JsonPipe, NgClass  } from '@angular/common';
import { CrmUsersService } from '../../views/crm-users/services/crm-users.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';  
import { CookieService } from '../../../services/cookie.service';
import { ChatService } from '../chat/services/chat.service';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { MessageService  } from 'primeng/api';
import { EmojiPipe } from '../../../pipes/emoji.pipe'; 
import { Router, RouterModule } from '@angular/router'; 
import { IconFieldModule } from 'primeng/iconfield'; 
import { MultiSearchComponent } from '../multi-search/multi-search.component';
import { environment as env } from '../../../../environments/environment';
import { ChipModule } from 'primeng/chip';
import { FirebaseAuthService } from '../../views/auth/services/firebase-auth.service';
 

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [   
    RouterModule,   
    JsonPipe,
    DatePipe,
    EmojiPipe,
    NgClass,   
    MatToolbarModule,    
    MatIconModule, 
    MatButtonModule,
    MatChipsModule,
    AvatarModule,
    ToastModule, 
    ButtonModule, 
    RippleModule ,
    IconFieldModule,
    ChipModule,
    MultiSearchComponent     
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [MessageService]
})
export class HeaderComponent {

  @Input() title: string = ''; 
  @Input() subtitle: string = ''; 
  @Output() toggeleSidenav = new EventEmitter<string>(); 

  theme = 'dark-theme';

  environment = env; 

  fbAuthService = inject(FirebaseAuthService);
  crmUserService = inject(CrmUsersService);  
 
  public sanitizedImageUrl: SafeUrl | undefined;   
  
  loggedUser = signal<any>(null);  
 
  constructor( 
    private sanitizer: DomSanitizer , 
    private cookieService: CookieService,
    private chatServ: ChatService,
    private router: Router,
    private messageService: MessageService 
  ) { 
      effect(() => {   

        // console.log('>>>>> header CURRENT USER ? ', this.fbAuthService.currentUserSignal());
        this.loggedUser.set(this.fbAuthService.currentUserSignal());
        const currUserID = this.fbAuthService.currentUserSignal()?.uid ;
        if(currUserID){ 
          this.chatServ.listenForNewMessages(currUserID); 
          // totalmente inutile :) 
          const photoUrl = this.fbAuthService.currentUserSignal()?.photoURL;
          const uniqueUrl = `${photoUrl}?timestamp=${new Date().getTime()}`; 
          this.sanitizedImageUrl = this.sanitizer.bypassSecurityTrustUrl(uniqueUrl);           
        }      
      }, { allowSignalWrites: true } as CreateEffectOptions);   
     
  } 


  ngOnInit() { 

    // set custom theme
    this.theme = this.cookieService.get('desired-theme');
    if(!this.theme) this.theme = 'dark-theme';
    document.body.classList.add(this.theme); 

    if(this.theme === 'dark-theme') {  //* NEW WAY   https://primeng.org/theming
      const element = document.querySelector('html')!;  //* NEW WAY
      element.classList.toggle('my-app-dark');  //* NEW WAY
    }  //* NEW WAY

    //*TODO °°°°°  new way  °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
    // const currentTheme = document.body.getAttribute('data-on-theme');    
     document.body.setAttribute('data-on-theme', this.theme);    
    //*TODO °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°

    this.chatServ.newMessages$.subscribe( (newMessages) => {
      if(newMessages.length > 0) {  
        if(this.chatServ.hideToasts$.value) return;
        this.showToasts(newMessages);
      }
    });
  } 

  // TOAST MESSAGES ***************

  showToasts(newMessages: any[] ) {  
      newMessages.forEach( (newMess) => { 
        const mess = {      
          sticky: true, 
          severity: 'success', 
          detail: newMess.text,
          closable: true,
          draggable: true,
          // life: 10000,
          data: {...newMess}
        }; 
        this.messageService.add(mess);
      });
      
      const audio = new Audio('assets/sounds/ring.mp3');  
      audio.volume = 0.6; //  50%
      audio.onended = () => {
        // some logic
      };
      audio.play();
  }
  
  onReject(message: any) { 
    console.log('>>>>> response', message); 
    this.chatServ.markMessageAsRead(message.chatId, message.id); 
  }

  response(message: any) {   
    this.messageService.clear(); // close all
    this.router.navigate(['/crmusers/' + message.data.chatId]); 
  } 
  
  //********************************

  customTheme() { 
    document.body.setAttribute('data-on-theme', 'on-theme');    
  }

  toggleTheme() {
    const element = document.querySelector('html')!;  //* NEW WAY
     element.classList.toggle('my-app-dark');  //* NEW WAY

    if(document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
      this.theme = 'light-theme'; 
    } else {
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
      this.theme = 'dark-theme'; 
    }
    this.cookieService.set('desired-theme', this.theme, 365);

    //*TODO °°°°°  new way  °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°  
    document.body.setAttribute('data-on-theme', this.theme);    
    //*TODO °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
  }

  // toggleTheme() { 
  //   if(document.body.classList.contains('dark-theme')) {
  //     document.body.classList.remove('dark-theme');
  //     document.body.classList.add('light-theme');
  //     this.theme = 'light-theme'; 
  //   } else {
  //     document.body.classList.remove('light-theme');
  //     document.body.classList.add('dark-theme');
  //     this.theme = 'dark-theme'; 
  //   }
  //   this.cookieService.set('desired-theme', this.theme, 365);

  //    //*TODO °°°°°  new way  °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°  
  //   document.body.setAttribute('data-on-theme', this.theme);    
  //   //*TODO °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
  // } 

  toggleSidenav() {
    this.toggeleSidenav.emit('');
  } 


 

}

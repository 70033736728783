    
<!-- DON'T REMOVE pFocusTrap to avoid auto focus -->
<h2 pFocusTrap  >
    <img src="assets/img/logoOn.png" alt="On Logo">      
</h2> 

      
 <!-- MENU  -->
 <p-panelMenu [model]="menuitems" [multiple]="false" />    

 <!-- COMBO LANGUAGES  --> 
<p-select 
    [options]="comboLanguages()"    
    [(ngModel)]="selectedLang" 
    optionLabel="label" 
    variant="filled" 
    size="large"
    [filter]="false" 
    filterBy="label" 
    [showClear]="false" 
    placeholder = "Select Language..." 
    class="dropdownLang w-full md:w-56">
    <ng-template #selectedItem let-selectedOption>
        <div class="flex items-center gap-2">
            <img  [src]="selectedOption?.ico"   style="width: 30px" />
            <div>{{ selectedOption?.label }}</div>
        </div>
    </ng-template>
    <ng-template let-language   #item>
        <div class="flex items-center gap-2"   (click)="selectLang(language)" > 
            <img [src]="language.ico"  style="width: 30px" />
            <div>{{ language.label }}</div>
        </div>
    </ng-template>
</p-select>
    

     
<app-app-version style="position: fixed; bottom: 10px; left: 20px;" />  

  
